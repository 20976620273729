
const TileListToggle = ({
  
  } = {}) => ({

    data: () => ({
        isCardView: true,
      }),
      mounted () {
      
      },
      computed: {
        isListView () {
          return !this.isCardView
        },
      },
      methods: {

        toggleCardView () {
          this.isCardView = true
        },
        toggleListView () {
          this.isCardView = false
        }

      }

  });

export default TileListToggle


