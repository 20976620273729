import Vue from 'vue'

Vue.component('ExpandableContent', {
  data: () => ({
    isContentExpanded: false
  }),
  methods: {
    open () {
      this.isContentExpanded = true
    },
    close () {
      this.isContentExpanded = false
    }
  }
})