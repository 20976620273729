import Vue from 'vue'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

Vue.component('pageNav', {
  data: () => ({
    isNavLocked: false,
    isBottomLocked: false,
    isDrawerOpen: false,
    pageNavTop: window.pageYOffset + (document.getElementById('page-nav') ? document.getElementById('page-nav').getBoundingClientRect().top : 0)
  }),
  methods: {
    handleScroll () {
      var navHeight = document.getElementById('page-nav').offsetHeight
      var screenHeight = screen.height
      var pageHeight = document.getElementById('app').offsetHeight
      var footerHeight = document.getElementById('footer').offsetHeight

      this.isNavLocked = window.pageYOffset > (this.pageNavTopComputed - 110)
      this.isDrawerOpen = window.pageYOffset > 500
      this.isBottomLocked = window.pageYOffset > (pageHeight - footerHeight - navHeight - 212)
    }
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
})

Vue.component('navLink', {
  props: ['link', 'isLastLink'],
  data: () => ({
    isActive: false,
    isTest: false,
    pageNavTop: window.pageYOffset + (document.getElementById('page-nav') ? document.getElementById('page-nav').getBoundingClientRect().top : 0)
  }),
  computed: {
    id () {
      if (this.link && this.link.url)
        return this.link.url.replace('#', '')
    },
    pageNavTopComputed() {
      return window.pageYOffset + (document.getElementById('page-nav') ? document.getElementById('page-nav').getBoundingClientRect().top : 0)
    }
  },
  methods: {
    handleScroll () {
      const pageHeight = document.getElementById('app').offsetHeight // using #app because body height is set to 100%
      const windowBottomPosition = window.innerHeight + Math.ceil(window.pageYOffset)
      const isWindowScrolledBottom = windowBottomPosition >= pageHeight

      if (isWindowScrolledBottom) {
        this.isActive = this.isLastLink
        return
      }

      const els = document.querySelectorAll('.section--anchored')
      for (var i = 0; i < els.length; i++) {
        const el = els[i]
        if (el.id === this.id) {
          const nextEl = els[i + 1]
          // IF (the section for this link is within 120px from the top of the window)
          // AND (there's no next section OR the next section is not within 120px of the top of the window)
          if (el.getBoundingClientRect().top <= 120 && (!nextEl || nextEl.getBoundingClientRect().top > 120)) {
            this.isActive = true
          } else {
            this.isActive = false
          }
        }
      }
    },
    scrollToId(elementID) {
      elementID = elementID.replace('#', '')
      
      return new Promise((resolve, reject) => {
        const el = document.getElementById(elementID)

        if (!el)
          return reject(`Could not scroll, no element has id ${elementID}`)

        const pageHeight = document.getElementById('app').offsetHeight // using #app because body height is set to 100%
        const elPageTopOffset = el.getBoundingClientRect().top + window.pageYOffset
        const elPageBottomOffset = pageHeight - elPageTopOffset
        const scrollOffset = elPageBottomOffset > window.innerHeight - 70 ? 110 : 0

        return gsap.to(window, {
          duration: 1.2,
          scrollTo: {
            y: document.getElementById(elementID),
            offsetY: scrollOffset,
            // iOS Fix
            autoKill: false
          },
          onComplete: resolve
        })
      })
    }
  },
  created: function () {
    this.handleScroll
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
})