
import LandingPage from '../mixins/LandingPage'

//TODO: clean up methods and consolidate with landingpage and hklandingpage mixin 

const HKLandingPageMixin = ({
  endpoint = undefined,
  searchOnLoad = false, //can be removed
  paginationFilterNames = [ 'page' ]
} = {}) => ({

  data: () => ({
    keyword: '',
    advancedFiltersAreOpen: false,
    isPageListOpen: false,
   // page: 0, //page is computed that uses a filter 
  }),

  mixins: [LandingPage({endpoint, searchOnLoad, paginationFilterNames})],

  computed: {
    results () {
      return this.response ? this.response.results : []
    },
    hasResults () {
      return this.results.length > 0
    },
    hasNoResults () {
      return this.hasResults === false
    },
    showNoResultsMessage () {
      return !this.isSearching && this.hasNoResults && this.hasAnyFilters
    },
    count () {
      return this.results.length
    },
    //FROM RESPONSE / INITIAL RESULTS
    total () {
      return this.response ? this.response.total : 0
    },
    //FROM RESPONSE / INITIAL RESULTS
    pageSize () {
      return this.response? this.response.pageSize : 1
    },
    displayPage () {
      return this.page + 1;
    },
    firstResultDisplayed () {
      return (this.page * this.pageSize) + 1
    },
    lastResultDisplayed() {
      return this.firstResultDisplayed + this.count - 1
    },
    //internal count of page starts at zero to keep consistency with all other apis
    firstPage () {
      return this.page === 0
    },
    pages () {
      return Math.ceil(this.total / this.pageSize)
    },
    lastPage () {
      return (this.page + 1) >= this.pages
    },
    hasSearchActivity () {
      return this.hasAnyFilters || this.hasResults || this.isSearching || this.showNoResultsMessage
    },
  },

  //update the root method based on this computed property, so we can hide stuff outside the search landing component
  watch: {
    hasSearchActivity: function(val){
      // console.log(val);
      this.$root.hideOtherSectionsOnSearchLanding = val;
    }
  },

  methods: {
    performKeywordSearch () {
      if (this.keyword !== '') {
        this.clearAllFiltersBeforeNewSearch()
          .then(this.setFilter('keyword', this.keyword))
          .then(() => {this.keyword = ''})
          .then(_ => this.scrollTo({
            offset: this.offset
          }))
      }
    },

    getDropdownValue (key) {
      const filter = this.getFilter(key)
      return filter
        ? filter.value
        : undefined
    },
    updateDropdown (target, dropdown, value) {
      target.blur()
      if (!value) {
        this.clearFilter(dropdown.value) 
      } else {
        this.setFilterAndClearPage(dropdown.value, value, this.getDropdownLabel(dropdown.options, value))
          .then(_ => this.scrollTo({
            offset: this.offset
          }))
      }
    },
    getDropdownLabel (options, value) {
      const matchingDropdown = options
        .filter(option => option.value == value)[0]

      return matchingDropdown
        ? matchingDropdown.label
        : undefined
    },
       
    toggleAdvancedFilters () {
      if (this.advancedFiltersAreOpen == false) {
        this.advancedFiltersAreOpen = true
      } else {
        this.advancedFiltersAreOpen = false
      }
    },

    togglePageList () {
      this.isPageListOpen = !this.isPageListOpen
    },

    newAnimation () {
      const { advanced } = this.$refs
      return (advanced)
        ? new TimelineLite()
          .set(advanced, { height: 'auto' })
          .from(advanced, 0.2, { height: 0 })
          .fromTo(advanced, 0.2,
            { autoAlpha: 0, transform: 'translateY(1rem)' },
            { autoAlpha: 1, transform: 'translateY(0)' }
          )
          .eventCallback('onReverseComplete', function () {
            TweenLite.set(advanced, { height: 0 })
          })
          .pause()
        : undefined
    },

    clearAll () {
      return this.clearAllFilters()
        .then(this.clearResponse)
    },
    
    nextPage() {
      this.goToPage(this.page + 1)
    },

    prevPage() {
      this.goToPage(this.page - 1)
    },

    goToPage(value) {
      //page filter value is zero indexed passed in value is decremented

      this.setFilterAndClearPage('page', value)
        .then(_ => this.scrollTo({
          offset: this.offset
        })
        .then(this.isPageListOpen =  false))
    },
  }
})


export default HKLandingPageMixin

