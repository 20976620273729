import Vue from 'vue'
import InsightLandingPageMixin from '../mixins/InsightLandingPage'

Vue.component('EventLanding', {

  mixins: [
    //news only
    InsightLandingPageMixin({
      endpoint: '/api/EventApi/Events'
    })
  ],

})
