import Vue from 'vue'
import InsightLandingPageMixin from '../mixins/InsightLandingPage'

Vue.component('NewsLanding', {
  mixins: [
    InsightLandingPageMixin({
      endpoint: '/api/NewsApi/News'
    })
  ],
})
