import Vue from 'vue'
import Blockquotes from './mixins/Blockquotes';
import VideoControls from './mixins/VideoControls';
import ContentJumpLinks from './mixins/ContentJumpLinks';

new Vue({
  el: '#app',
  data: () => ({
    modalOpen: false,
    email: '',
    hideOtherSectionsOnSearchLanding: false, //used to hide the other things on a search landing page
    hasInlineSubscribe: false, //set by subscribe vue component injected into rich text editor
  }),
  methods: {
    toggleModal(email, event) {
      if (event)
        event.preventDefault()

      this.modalOpen = !this.modalOpen
      this.email = email
    },
    accept() {
      var mailTo = 'mailto:' + this.email
      window.location.href = mailTo
      console.log(this.email)
      this.toggleModal()
    },
    onChange (event) {
      event.srcElement && (window.location = event.srcElement.value)
    }
  },
  mixins: [
    Blockquotes(),
    VideoControls(),
    ContentJumpLinks()
  ]
})
