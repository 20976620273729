import Vue from 'vue';

Vue.component('Carousel', {
  props: [ 'slides' ], //not necessary, can replace with just 'total' prop
  data: () => ({
    activeSlide: 0,
    clicked: false
  }),
  computed: {
    total() {
      return this.slides.length
    },
    count() {
      return this.activeSlide + 1
    },
    showControls() {
      return this.total > 1;
    },
  },
  methods: {
    
    isActiveSlide(index) {
      return index === this.activeSlide
    },
    isNextSlide(index) {
      if(this.total === 1)
        return false;
      if ( this.activeSlide < this.total -1 ) {
        return index === this.activeSlide + 1
      } else {
        return index === 0
      }
    },
    stallClick () {
      this.clicked = true

      setTimeout(function(){
        this.clicked = false;
      }.bind(this),500);
    },
    prevSlide() {
      this.activeSlide += this.total - 1
      this.activeSlide %= this.total

      this.stallClick()
    },
    nextSlide() {
      this.activeSlide += 1
      this.activeSlide %= this.total

      this.stallClick()
    },
    selectSlide(index) {
      this.activeSlide = index

      this.stallClick()
    }
  },
  mounted() {
    
  }
})
