import Vue from 'vue'
import InsightLandingPageMixin from '../mixins/InsightLandingPage'

Vue.component('InsightLanding', {

  mixins: [
    // 
    InsightLandingPageMixin({
      endpoint: '/api/InsightApi/Insights'
    })
  ],

})
