import { TweenMax } from 'gsap'
import 'gsap/ScrollToPlugin'

/**
 * This mixin scrapes the page content for links that link to elsewhere in the
 * content, and overrides the default browser jump-to functionality. This is so
 * we can account for the sticky site header and use a nice scroll animation.
 */
const ContentJumpLinks = ({} = {}) => ({
  mounted() {
    this.debounce(this.handleUrlHash(), 250)
    this.hijackJumpLinksInContent()
  },
  methods: {
    scrollToID(elementID, seconds) {
      const error = `Could not scroll, no element has id or name ${elementID}`
      elementID = elementID.replace('#', '')
      var offset  =  110;
      const el = document.getElementById(elementID)
        || document.getElementsByName(elementID)[0]

      return new Promise((resolve, reject) =>
        (el)
          ? TweenMax.to(window, seconds, {
            scrollTo: {
              y: el,
              offsetY: offset,
              // iOS Fix
              autoKill: false
            },
            onComplete: resolve
          })
          : reject(error)
      )
    },
    handleJumpLinkClick(event) {
      event.preventDefault()
      this.scrollToID(event.currentTarget.hash, 1.2)
    },
    handleUrlHash(){
      if(window.location.hash !== ""){
        window.setTimeout( () => this.scrollToID(window.location.hash, 0),100)
      }
    },
    debounce(func, wait, immediate) {
      var timeout;
    
      return function executedFunction() {
        var context = this;
        var args = arguments;
          
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
    
        var callNow = immediate && !timeout;
      
        clearTimeout(timeout);
    
        timeout = setTimeout(later, wait);
      
        if (callNow) func.apply(context, args);
      };
    },
    hijackJumpLinksInContent() {
      const bodyCopyNodes = document.querySelectorAll('.body-copy')
      
      for (var i = 0; i < bodyCopyNodes.length; i++) {
        const links = bodyCopyNodes[i].querySelectorAll('a')
        
        for (var j = 0; j < links.length; j++) {
          const link = links[j]
          
          /*
          * Client uses a weird method for entering scroll-to points that isn't
          * compatible with gsap scrollTo, so I'm fixing all the "broken" links
          * here, client-side.
          */
         const isBrokenAnchorPoint = link.name && !link.href
         
         if (isBrokenAnchorPoint) {
           const comment = document.createComment(link.outerHTML)
           
           link.parentNode.insertBefore(comment, link)
           link.href = 'javascript:void(0)'
          }
          
          const isInPageLink = link.hash && link.href.match(window.location.pathname)
          
          if (isInPageLink) {
            link.addEventListener('click', this.handleJumpLinkClick)
          }
        }
      }
    }
  }
})

export default ContentJumpLinks