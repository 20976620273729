
const VideoControls = ({
  
} = {}) => ({

  data: () => ({

    }),
    methods: {

      playVideo () {
        event.target.play()
        event.target.controls = true
      }
    }
});

export default VideoControls