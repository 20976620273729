import HKLandingPage from '../mixins/HkLandingPage'

//TODO: clean up methods and consolidate with landingpage and hklandingpage mixin 

const InsightLandingPageMixin = ({
    endpoint = undefined,
    searchOnLoad = false,
    paginationFilterNames = [ 'page' ]
  } = {}) => ({
    mixins: [
        HKLandingPage({
          endpoint: endpoint
        })
      ],
    data: () => ({
        animation: undefined,
      }),
      mounted () {
        this.$set(this.filters, 'topic', [])

        this.animation = this.newAnimation()
    
        if(this.hasInitialFilters) {
          //just keyword, or just topic , or keyword and topic
          if( (this.filterCount == 1 && (this.getFilter('keyword') || this.getFilter('topic')))
            || (this.filterCount === 2 && this.getFilter('keyword') && this.getFilter('topic'))) {
            //not an advanced filter set
          }
          else {
            //we have an advanced filter set so load the filter list expanded
            this.advancedFiltersAreOpen = false;
          }
        }
      },
      computed: {

      },
      methods: {
        searchByTopic (topicFilter) {
          if (this.filters.topic && this.filters.topic.filter(tf => tf.value == topicFilter.value).length) {
            // Analogous to LandingPage.clearFilter('topic'), but we can't clear topic filter all-together
            this.onlySetFilter('topic', topicFilter.value)
              .then(this.clearPaginationFilters)
              .then(this._clearResponseIfNoFilters)
              .then(this.pushUrl)
              .then(this._searchIfThereAreFilters)
          } else {
            this.setFilterAndClearPage('topic', topicFilter.value, topicFilter.label)
              .then(_ => this.scrollTo({
                offset: this.offset
              }))
          }
        },
    
      }

  });

export default InsightLandingPageMixin
