import Vue from 'vue'

Vue.component('practiceList', {
  data: () => ({
    isViewCategories: true,
    isViewLetters: false
  }),
  methods: {
    openLetterView () {
      this.isViewLetters = true
      this.isViewCategories = false
    },
    openCategoryView () {
      this.isViewLetters = false
      this.isViewCategories = true
    },
  }
})