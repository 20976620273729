
const FormatLabel = ({} = {}) => ({

    props : {

        loadXMoreLabelTemplate: {
            type: String
        },
        showingXOfYLabelTemplate: {
            type: String
        },
    },

    data: () => ({

    }),
    mounted () {
    
    },
    computed: {
        loadXMoreLabel () {
            return this.formatLabel(this.loadXMoreLabelTemplate)
        },
        showingXofYLabel () {
            return this.formatLabel(this.showingXOfYLabelTemplate)
        },
    },
    methods: {

        formatLabel(labelTemplate) {
            if (labelTemplate)

                return labelTemplate
                // .replace('{FIRST}', '1') // will this ever be paged ? 
                .replace('#VISIBLE', this.currentResultCount)
                .replace('#TOTAL', this.totalResultCount)
                .replace('#COUNT', this.nextPageCount)

          return ''
        },

    }

  });

export default FormatLabel
