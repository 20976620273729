import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents)

Vue.component('HomeHero', {
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    autoplay: Boolean,
    initialSlide: Number, // less than 1 means "random'
    delay: Number // seconds
  },
  data () {
    return {
      stopped: this.autoplay === false,
      activeSlide: this.initialSlide >= 1
        ? Math.min(this.initialSlide, this.slides.length) - 1
        : Math.floor(Math.random() * this.slides.length), // randomize intial slide
      timeOut: Math.max(1, this.delay) * 1000, // convert to milliseconds
      innerWidth: window.innerWidth,
    }
  },
  computed: {
    totalSlides () {
      return this.slides.length - 1
    },
    isMobile () {
      return this.innerWidth < 896
    },
    mobilePosition() {
      return this.isMobile && this.activeSlide > 0
        ? 'calc(-'+ this.activeSlide * 100 + 'vw + ' + this.activeSlide * 40 + 'px + 15px)'
        : '0'
    },
    tabMinWidth () {
      return this.isMobile
        ? ((this.totalSlides + 1) * 100 + 'vw')
        : '0'
    },
  },
  methods: {
      pause() {
        if(this.stopped) {
          this.stopped=false
          this.switchSlide(this.activeSlide)
        } else {
          this.selectSlide(this.activeSlide)
        }
          
    },
    updateSize () {
      this.innerWidth = window.innerWidth

      if (!this.isMobile) {
        // set min carousel height according to largest slide
        const containerEls = this.$el.querySelectorAll('.container')
        const navEl = this.$el.querySelector('.home-hero__navigation')
        const navHeight = navEl ? navEl.clientHeight : 0
        let tallestContainerHeight = 0

        containerEls.forEach(el => {
          if (el.clientHeight > tallestContainerHeight)
            tallestContainerHeight = el.clientHeight
        })

        this.$el.style.minHeight = tallestContainerHeight + navHeight + 'px'
      } else {
        // unset min carousel height
        this.$el.style.minHeight = ''
      }
    },
    isActiveSlide(index) {
      return index === this.activeSlide
    },
    selectSlide(index) {
      this.activeSlide = index
      this.clearTimer()
      this.stopped = true;
    },
    switchSlide(index) {
      if (this.activeSlide < this.totalSlides)
        this.activeSlide = index + 1
      else
        this.activeSlide = 0

      this.clearTimer()
      this.timeInterval()
    },
    clearTimer() {
      clearTimeout(this.timer)
    },
    timeInterval() {
      if (this.autoplay === false)
        return

      this.timer = setTimeout(() => {
        if (!this.isMobile) {
          this.switchSlide(this.activeSlide);
        }
      }, this.timeOut)
    },
    swipeLeftHandler() {
      if (this.activeSlide < this.totalSlides)
        this.activeSlide += 1
      else
        this.activeSlide = 0

      this.clearTimer()
      this.timeInterval()
    },
    swipeRightHandler() {
      if (this.activeSlide > 0)
        this.activeSlide -= 1
      else
        this.activeSlide = this.totalSlides

      this.clearTimer()
      this.timeInterval()
    }
  },
  created () {
    this.timeInterval()
  },
  mounted () {
    this.updateSize()

    window.addEventListener('resize', () => {
      this.updateSize()
    })

    this.$nextTick(() => {
      //don't apply transitions until initial slide is rendered
      this.$refs.wrapper.classList.add('apply-transitions')
    })
  }
})