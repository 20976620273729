import axios from 'axios';
import Vue from 'vue'
import TileListToggle from '../mixins/TileListToggle';
import FormatLabel from '../mixins/FormatLabel';


// be mindful of similar component load more with topics (on blog landing page)

Vue.component('LoadMore', {
  mixins: [ TileListToggle({}) , FormatLabel({}) ],
  props: {
    endpoint: {
      required: true,
      type: String
    },
    initialResults: {
      required: true,
      type: Array,
    },
    totalResultCount: {
      required: true,
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    },
  },
  data: () => ({
    page: 0,
    isLoading: false,
    loadedResults: []
  }),
  computed: {
    results () {
      return this.initialResults.concat(this.loadedResults)
    },

    //insights landing
    beforeViolatorResults () {
      return this.results.slice(0,6);
    },
    afterViolatorResults() {
      return this.results.slice(6);
    },

    
    hasMore () {
      return this.results.length < this.totalResultCount
    },
    nextPage () {
      const hasQuery = this.endpoint.indexOf('?') !== -1
      return [
        this.endpoint,
        (hasQuery ? '&' : '?') + 'page=' + (this.page + 1)
      ].join('')
    },
    nextPageCount () {
      var remaining = this.totalResultCount - this.results.length
      return Math.min(remaining, this.pageSize);
    },
    currentResultCount () {
      return this.results.length;
    }
  },
  methods: {
    loadMore () {
      if(this.isLoading) return;
      
      this.isLoading = true;
      return axios.post(this.nextPage)
        .then(({ data }) => {
          console.log(data.results)
          this.loadedResults = this.loadedResults.concat(data.results)
          this.page += 1
          this.isLoading = false;
        })
    }
  }
})
