import Vue from 'vue'
import HKLandingPage from '../mixins/HkLandingPage'
import Typeahead from '../mixins/Typeahead'
import axios from 'axios';


Vue.component('PeopleLanding', {
  data: () => ({
    animation: undefined,
    nameKeyword : '',
    isLettersOpen: false
  }),
  mounted () {
    this.animation = this.newAnimation()

    //open advanced filters by default per client request am-12782
    this.advancedFiltersAreOpen = true;

    //pretty sure none of the below code does anything since has initial filters is never set to true on people landing
    if(this.hasInitialFilters) {
      if(this.filterCount === 1 && (this.getFilter('keyword') || this.getFilter('name')) || this.filterCount === 2 && (this.getFilter('keyword') && this.getFilter('name'))) {
        //do nothing, only the keyword is set which is not a hidden filter
      } else {
        //we have an advanced filter set so load the filter list expanded
        this.advancedFiltersAreOpen = false;        
      }      
    }      
  },
  computed: {
    //override typeahead functions that deal with using nameKeyword instead of keyword
    typeaheadKeyword () {
      return this.nameKeyword //override if necessary in parent component (like people landing)
    },
  },
  mixins: [
    Typeahead({
      typeaheadEndpoint: '/api/ProfessionalsApi/LawyerTypeahead'
    }),
    HKLandingPage({
      endpoint: '/api/ProfessionalsApi/Lawyers'
    })
  ],
  methods: {

    /*Once a search has been executed, selection of a new letter will be an
      addition to any other search filters already applied. */
    searchByLetter (letter) {
      this.isLettersOpen = false
      this.setFilterAndClearPage('letter', letter)
        .then(_ => this.scrollTo({
          offset: this.offset
        }))
    },

    performNameSearch(){
      if (this.nameKeyword !== '') {
        this.clearAllFiltersBeforeNewSearch()
          .then(this.setFilter('name', this.nameKeyword))
          .then(() => {this.nameKeyword = ''})
          .then(_ => this.scrollTo({
            offset: this.offset
          }))
      }
    },
    
    toggleLetters () {
      this.isLettersOpen = !this.isLettersOpen
    },

  }
})
