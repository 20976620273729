import Vue from 'vue'
import HKLandingPage from '../mixins/HkLandingPage'


Vue.component('CaseStudyLanding', {
  data: () => ({
    animation: undefined,
  }),
  mounted () {
    this.animation = this.newAnimation()

    if(this.hasInitialFilters) {
      if(this.filterCount === 1 && this.getFilter('keyword')) {
        //do nothing, only the keyword is set which is not a hidden filter
      } 
      else {
        //we have an advanced filter set so load the filter list expanded
        this.advancedFiltersAreOpen = false;
      }
    }
      
  },
  computed: {

  },
  mixins: [
    HKLandingPage({
      endpoint: '/api/CaseStudyApi/CaseStudies'
    })
  ],
  methods: {

    searchByTopic (topicFilter) {
      this.setFilterAndClearPage('topic', topicFilter.value, topicFilter.label)
        .then(_ => this.scrollTo({
          offset: this.offset
        }))
    },

  }
})
