import Vue from 'vue'

Vue.component('ExpandingList', {
  props: {
    totalListings: {
      type: Array
    },
    increment: {
      type: Number,
      default: 3
    },
    count: {
      type: Number,
      default: 3
    }
  },
  data: () => ({
    isCardView: true,
    isListView: false
  }),
  computed: {
    hasMoreListings () {
      return this.count < this.totalListings.length
    },
    total () {
      return this.totalListings.length
    },
    remainder () {
      return this.totalListings.length - this.count
    },
    nextAmount () {
      if (this.remainder > this.increment) {
        return this.increment
      } else {
        return this.remainder
      }
    }
  },
  methods: {
    loadMoreListings () {
      if (this.remainder > this.increment) {
        this.count += this.increment
      } else {
        this.count += this.remainder
      }
    },
    isVisible (index) {
      return index < this.count
    },
    toggleCardView () {
      this.isCardView = true
      this.isListView = false
    },
    toggleListView () {
      this.isCardView = false
      this.isListView = true
    }
  }
})
