import Vue from "vue"
import { TimelineLite, TweenLite } from 'gsap'

const newAnimation = (el) =>
  (el)
    ? new TimelineLite()
      .set(el, { height: 'auto', paddingTop: '1rem' })
      .from(el, 0.4, { height: 0, paddingTop: 0, overflow: 'hidden' })
      .fromTo(el, 0.4,
        { opacity: 0, transform: 'translateY(1rem)' },
        { opacity: 1, transform: 'translateY(0)' }
      )
      .eventCallback('onReverseComplete', function () {
        TweenLite.set(el, { height: 0, paddingTop: 0 })
      })
      .pause()
    : undefined

Vue.directive('Expand', {
  bind (el, { value }) {
    el.animation = newAnimation(el)

    if (value) {
      el.animation.progress(1)
    } else {
      el.animation.progress(0)
    }
  },
  update (el, { value }) {
    if (value) {
      el.animation.play()
    } else {
      el.animation.reverse()
    }
  }
})