import Vue from 'vue'

Vue.component('ThreeColumns', {
  props: [ 'items' ],
  computed: {
    leftItems () {
      return this.items.slice(0, Math.ceil(this.items.length / 3))
    },
    middleItems () {
      return this.items.slice(Math.ceil(this.items.length / 3), Math.ceil(2 * this.items.length / 3))
    },
    rightItems () {
      return this.items.slice(Math.ceil(2 * this.items.length / 3), this.items.length)
    }
  }
})
