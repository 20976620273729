import Vue from 'vue'

Vue.component('CategoryList', {
  data: () => ({
    
  })
})

Vue.component('CategoryExpand', {
  data: () => ({
    isOpen: false
  }),
  methods: {
    openCat() {
      this.isOpen = true
    }
  }
})