
const Blockquotes = ({
  
} = {}) => ({

  data: () => ({

    }),
    mounted () {
      this.blockquoteWrap()
    },
    methods: {

      blockquoteWrap () {
        const els = document.querySelectorAll('blockquote')
        els.forEach((el) => {
          var spanWrap = document.createElement("span")

          while(el.firstChild)
            spanWrap.appendChild(el.firstChild)

          el.appendChild(spanWrap);
        })
      }

    }

});

export default Blockquotes