import Vue from 'vue'

Vue.component('AccordionSection', {
  // props: [ 'accordions' ], //TODO: seems unnecessary, removing 
  data: () => ({
    allAccordionsOpen: false,
    isOpen: false,
  }),
  methods: {
    expandAllAccordions () {
      this.allAccordionsOpen = !this.allAccordionsOpen
    }
  }
})

Vue.component('accordionNode', {
  props: [ 'accordion', 'allAccordionsOpen' ],
  data: () => ({
    isOpen: false
  }),
  watch: {
    allAccordionsOpen (state) {
      this.isOpen = state
    }
  },
  methods: {
    toggleAccordion () {
      this.isOpen = !this.isOpen
    }
  }
})
