import Vue from 'vue'

Vue.component('Columns', {
  props: [ 'items' ],
  computed: {
    leftItems () {
      return this.items.slice(0, Math.ceil(this.items.length / 2))
    },
    rightItems () {
      return this.items.slice(Math.ceil(this.items.length / 2))
    }
  }
})
