import Vue from 'vue'

Vue.component('ArticleListItem', {
  template: '#article-list-item',
  props: ['link', 'metaData', 'date', 'readingtime'],
  computed: {
    metaItems: function() {
      return (this.metaData || []).concat([this.date, this.readingtime]).filter(function(x, i) { return x }) //removes blanks
    }
  }
})