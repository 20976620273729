
import axios from 'axios';

const TypeaheadMixin = ({
    typeaheadEndpoint = undefined
  } = {}) => ({

    data: () => ({
        typeaheadResults: [],
        nonce: 0,
        index: -1,
        navBarSearch: false
      }),
      mounted () {
        window.addEventListener('keyup', (event) => {
          if(event.keyCode === 13 ||event.keyCode === 38 || event.keyCode === 40)
            this.keydown()
        })
        window.addEventListener('mouseover', (event) => {
          if(event.target.classList.contains('typeahead__result')){
            this.updateActive(event.target)
          }
          if(event.target.classList.contains('typeahead__link')){
            this.updateActive(event.target.parentNode)
          }
        })
        window.addEventListener('click', (event) => {
          if(event.target.classList.contains('navbar__search') || event.target.classList.contains('navbar__close-search')){
            this.navBarSearch = !this.navBarSearch
            this.index = -1;
          }
        })
      },
      computed: {
        shouldShowTypeahead () {
            return this.typeaheadKeyword.length > 2 && this.typeaheadResults.length > 0
          },
          typeaheadKeyword () {
            return this.keyword //override if necessary in parent component (like people landing)
          },
          typeaheadResultsList () {
            return this.navBarSearch ? document.getElementsByClassName('typeahead__results')[0].children : document.getElementsByClassName('typeahead__results')[1].children
          }
      },
      methods: {
        updateTypeahead () {
            console.log(this.nonce + ":" + this.typeaheadKeyword)
        
            if (this.typeaheadKeyword.length > 2) {
              this.nonce++;
              return axios.post(`${typeaheadEndpoint}?keyword=${encodeURIComponent(this.typeaheadKeyword)}&nonce=${this.nonce}`)
                .then(this.onTypeaheadSuccess)
                .catch(console.error)
            }
        },
        onTypeaheadSuccess(response){

          if(response.data.nonce == this.nonce) {
            console.log("accepting response with nonce " + response.data.nonce);
            this.typeaheadResults = response.data.results;
          }
          else {
            console.log("Ignoring response with nonce " + response.data.nonce);
          }
        },
        //'show all' button click 
        //can override this function if you don't want a keyword search
        showAllResults () {
          this.clearTypeahead();
          this.performKeywordSearch();
        },

        clearTypeahead () {
          this.typeaheadResults = [];
        },
        listeners() {
          /* Input Change Event */
          $(document).on('keydown', '.people-search__input', this.keydown )
        },
        infinite(num) {
          var results =  this.typeaheadResultsList
          if ( num >= results.length ) return 0
          if ( num < 0 ) return results.length - 1
          return num
        },
        removeActive() {
          for(var i = 0; i < document.getElementsByClassName('typeahead__active').length; i++ ){
            document.getElementsByClassName('typeahead__active')[i].classList.remove('typeahead__active')
          }
        },
        updateActive(toUpdate) {
          this.removeActive()
          toUpdate.classList.add('typeahead__active');
          this.index = Array.prototype.slice.call(this.typeaheadResultsList).findIndex(x => x.classList.contains('typeahead__active'))
        },
        keydown() {
          switch (event.keyCode ) {
            /* enter button */
            case 13:
                this.typeaheadResultsList[this.index].firstChild.click()
              return false
            break;
            /* up arrow */
            case 38:
              event.preventDefault()
              ;(this.index === null ) ? this.index = 0 : this.index = this.infinite( this.index - 1)
              this.removeActive()
              this.typeaheadResultsList[this.index].classList.add('typeahead__active')
              return false
            break;
            /* down arrow */
            case 40:
                event.preventDefault()
              ;(this.index === null ) ? this.index = 0 : this.index = this.infinite( this.index + 1)
              this.removeActive()
              this.typeaheadResultsList[this.index].classList.add('typeahead__active')
              return false
            break;
            default:
              return true
            break;
          }
        }

      }

  });

export default TypeaheadMixin


