import axios from 'axios';
import Vue from 'vue'
import TileListToggle from '../mixins/TileListToggle';
import FormatLabel from '../mixins/FormatLabel';


//similar to regular load more, but behaves quite differently
//blogs landing page 

Vue.component('LoadMoreWithTopics', {
  mixins: [ TileListToggle({}), FormatLabel({}) ],
  props: {
    topics: {
      type: Array
    },
    endpoint: {
      type: String
    },
    initialResults: {
      type: Array
    },
    initialTotalResultCount: {
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    },
  },

  data: () => ({
    topic: '',
    page: 1,
    results: [],
    totalResultCount: 0,
    isLoading: false,
  }),

  //unlike regular load more, we don't want the initial results to always show 
  mounted () {
    this.results = this.initialResults;
    this.totalResultCount = this.initialTotalResultCount;
  },

  computed : {
    computedEndpoint () {
      return this.endpoint + '&topic=' + this.topic;
    },

    hasMore () {
      return this.results.length < this.totalResultCount
    },

    nextPage () {
      const hasQuery = this.endpoint.indexOf('?') !== -1
      return [
        this.computedEndpoint,
        (hasQuery ? '&' : '?') + 'page=' + this.page
      ].join('')
    },

    nextPageCount () {
      var remaining = this.totalResultCount - this.results.length
      return Math.min(remaining, this.pageSize);
    },

    currentResultCount () {
      return this.results.length;
    },

    showNoResultsMessage () {
      return this.topic !== '' && this.results.length === 0;
    },
  },

  methods : {
    filterByTopic (value) {
      if(this.isLoading) return;
      
      //Selection of a selected topic will unselect it and re-sort the content accordingly
      if(this.topic !== '' && this.topic === value){
        this.topic = '';
        this.page = 0;
        this.search();
      }
      else if(this.topic !== value){
        this.topic = value;
        this.page = 0;
        this.search();
      }
    },

    isTopicActive(value) {
      return this.topic === value
    },

    search () {
      if(this.isLoading) return;
      
      this.isLoading = true;
      return axios.post(this.nextPage)
      .then(({ data }) => {
        console.log(data.results)
        this.results = data.results
        this.totalResultCount = data.total
        this.page += 1
        this.isLoading = false;
      })
    },

    loadMore () {
      if(this.isLoading) return;
      
      this.isLoading = true;
      return axios.post(this.nextPage)
        .then(({ data }) => {
          this.page += 1

          console.log(data.results)
          this.results = this.results.concat(data.results)
          this.isLoading = false;

        })
      },
  }
})
