import Vue from 'vue'
import HKLandingPage from '../mixins/HkLandingPage'
import Typeahead from '../mixins/Typeahead'


Vue.component('SiteSearchLanding', {
  data: () => ({
    animation: undefined,
  }),
  mounted () {
    this.animation = this.newAnimation()

    // if(this.hasInitialFilters) {
    //   if(this.filterCount === 1 && this.getFilter('keyword')) {
    //     //do nothing, only the keyword is set which is not a hidden filter
    //   } 
    //   else {
    //     //we have an advanced filter set so load the filter list expanded
    //     this.advancedFiltersAreOpen = false;
    //   }
    // }
      
  },
  computed: {
    //override mixin
    showNoResultsMessage () {
      return !this.isSearching && this.hasNoResults && this.getDropdownValue('keyword')
    },
    
    //override mixin
    hasSearchActivity () {
      return  this.hasResults || this.isSearching || this.showNoResultsMessage
    },
  },
  mixins: [
    HKLandingPage({
      endpoint: '/api/SiteSearchApi/SiteSearch'
    }),
    Typeahead({
      typeaheadEndpoint: '/api/SiteSearchApi/SiteSearchTypeahead'
    }),
  ],
  methods: {

    //override mixin
    performKeywordSearch () {
      if (this.keyword !== '') {
        // this.clearAllFiltersBeforeNewSearch()
          // .then(this.setFilter('keyword', this.keyword))
          this.setFilter('keyword', this.keyword)
          .then(() => {this.keyword = ''})
          .then(_ => this.scrollTo({
            offset: this.offset
          }))
      }
    },

    setSegmentFilter(segmentTag) {

      var keyword = this.getDropdownValue('keyword');

      //if no keyword value stored, set the filter but do not search
      if(!keyword){
        this.onlySetFilter('segment', segmentTag, '')
        return;
      }
      
      if(this.getDropdownValue('segment') !== segmentTag)
      {
        this.setFilterAndClearPage('segment', segmentTag, '')
          .then(_ => this.scrollTo({
            offset: this.offset
          }))
      }
    },

    isSegmentActive(segmentTag){

      if(segmentTag == ''){
        var filter = this.getFilter('segment');
        if(!filter) return true;
      }
      return this.isThisOptionActive('segment', segmentTag);
    }

  }
})
