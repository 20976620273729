import Vue from 'vue'
import axios from 'axios'
import Typeahead from '../mixins/Typeahead'


Vue.component('Navbar', {
  data: () => ({
    keyword: '',
    segment: '',
    isMenuOpen: false,
    isSearchOpen: false,
    isNavLocked: false,
    isDropdownOpen: false,
    scrollSearchCheck: false,
    // inKeywordInput: false,
  }),
  props: {
    SearchLandingUrl: {
      required: true,
      type: String
    }
  },

  mixins: [
    Typeahead({
      typeaheadEndpoint: '/api/SiteSearchApi/SiteSearchTypeahead'
    }),
  ],

  computed : {
    searchUrlWithQueryString(){
      var segmentSection = this.segment? `&segment=${this.segment}` : ''
      return `${this.SearchLandingUrl}?keyword=${encodeURIComponent(this.keyword)}${segmentSection}`
    },
    pageNavTopComputed () {
      return window.pageYOffset 
    }
  },

  methods: {
    openMenu () {
      this.closeSearch()
      this.isMenuOpen = true
    },
    closeMenu () {
      this.isMenuOpen = false
    },
    openSearch () {
      this.focusSearch()
      this.closeMenu()
      this.isSearchOpen = true
    },
    closeSearch () {
      this.unfocusSearch()
      this.keyword = ''
      this.isSearchOpen = false
    },
    toggleSearch () {
      if(this.isSearchOpen)
        this.closeSearch()
      else
        this.openSearch()
    },   
    toggleDropdown () {
      this.isDropdownOpen = !this.isDropdownOpen
    } ,
    handleScroll () {
      this.isNavLocked = window.pageYOffset > 0
    },
    unfocusSearch () {
      setTimeout(_ => {
        this.$refs.sitesearchinput.blur();
      }, 200)
    },
    focusSearch () {
      setTimeout(_ => {
        this.$refs.sitesearchinput.focus();
      },0)
    },
    debounce(func, wait, immediate) {
      var timeout;
    
      return function executedFunction() {
        var context = this;
        var args = arguments;
          
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
    
        var callNow = immediate && !timeout;
      
        clearTimeout(timeout);
    
        timeout = setTimeout(later, wait);
      
        if (callNow) func.apply(context, args);
      };
    },
    navigateToSiteSearchResults() {
      if(this.keyword)
        window.location.href = this.searchUrlWithQueryString
    },
 
  },
  created: function () {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('keydown', (event)=>{
        if(event.keyCode === 27) {
            console.log(event.keyCode, this.isSearchOpen, this.isDropdownOpen)
            if(this.isSearchOpen) {
                this.closeSearch()
            }
            if(this.isDropdownOpen) {
                this.toggleDropdown()
            }
        }
    } );
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
})
