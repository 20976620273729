import axios from 'axios';
import Vue from 'vue'
import TileListToggle from '../mixins/TileListToggle';
// import FormatLabel from '../mixins/FormatLabel';


// be mindful of similar component load more with topics (on blog landing page)

Vue.component('ViewMore', {
  mixins: [ TileListToggle({}) ],
  props: {
    results: {
      required: true,
      type: Array,
    },
    // totalResultCount: {
    //   required: true,
    //   type: Number
    // },
    // pageSize: {
    //   required: true,
    //   type: Number
    // },
  },
  data: () => ({
    page: 0,
    // loadedResults: []
  }),
  computed: {
    
    // hasMore () {
    //   return this.results.length < this.totalResultCount
    // },
    // nextPage () {
    //   const hasQuery = this.endpoint.indexOf('?') !== -1
    //   return [
    //     this.endpoint,
    //     (hasQuery ? '&' : '?') + 'page=' + (this.page + 1)
    //   ].join('')
    // },
    // nextPageCount () {
    //   var remaining = this.totalResultCount - this.results.length
    //   return Math.min(remaining, this.pageSize);
    // },
    // currentResultCount () {
    //   return this.results.length;
    // }
  },
  // methods: {
  //   loadMore () {
  //     return axios.post(this.nextPage)
  //       .then(({ data }) => {
  //         console.log(data.results)
  //         this.loadedResults = this.loadedResults.concat(data.results)
  //         this.page += 1
  //       })
  //   }
  // }
})
