import axios from 'axios'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

const empty = {
  filters: {},
  response: null
}

const LandingPageMixin = ({
  endpoint = undefined,
  searchOnLoad = false,
  paginationFilterNames = [ 'page' ]
} = {}) => ({

  props: [ 'settings' ],

  data: () => ({
    offset: 90, //height of fixed navbar for scrolling
    filters: empty.filters,
    isSearching: searchOnLoad,
    paginationFilterNames,
    response: empty.response,
    searchOnLoad,
    initialQueryParams: Array.from(new URLSearchParams(window.location.search))
      .map(([key, value]) => ({ key, value }))
  }),

  mounted () {
    //hydrate initial results + initial filter state from settings prop. This is the same kind of object that the API returns

    if(this.hasInitialFilters) {
      this.$set(this, 'filters', this.settings.initialFilters);
      this.$set(this, 'response', this.settings.initialResults);
    }

    const pathname = window.location.pathname
    this._setupHistoryListener(pathname)
    if (this.searchOnLoad) {
      this.search() //never used in this site, so far...
    } else {
      this.replaceUrl()
    }
  },

  destroyed () {
    const pathname = window.location.pathname
    this._removeHistoryListener(pathname)
  },

  computed: {

    hasInitialFilters () {
      return this.settings && this.settings.initialFilters && Object.keys(this.settings.initialFilters).length
    },

    filterList () {
      return Object.keys(this.filters)
        .map(key => ({
          key,
          value: this.filters[key].value != undefined
            ? this.filters[key].value
            : this.filters[key].map(({ value }) => value).join(','),
          label: this.filters[key].label != undefined
            ? this.filters[key].label
            : this.filters[key].map(({ label }) => label).join(', ')
        }))
        .filter(({ value }) => value)
    },


    page () {
      var pageFilter = this.getFilter('page');
      return pageFilter ? pageFilter.value : 0;
    },

    hasAnyFilters() {
      return this.filterCount > 0
    },

    filterCount () {
      return this.nonPaginationFilterList.length
    },

    nonPaginationFilterList () {
      return this.filterList
        .filter(({ key }) =>
          this.paginationFilterNames.indexOf(key) === -1
        )
    },

    stateToCache () {
      return {
        filters: this.filters,
        response: this.response
      }
    },

    queryString () {
        const filterString = this.initialQueryParams.filter(param =>
          // Preserve initial query params unless overwritten by filters
          !this.filterList.some(filter => filter.key === param.key)
        )
        .concat(this.filterList)
        .map(this._normalizeArray)
        .map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
        .join('&')

      return filterString
        ? `?${filterString}`
        : ''
    },

    apiUrl () {
      return (!endpoint)
        ? undefined
        : (endpoint.toLowerCase().indexOf('http') === 0)
          ? [ endpoint, this.queryString ].join('')
          : [ window.location.origin, endpoint, this.queryString ].join('')
    },

    fullUrl () {
      return [
        window.location.origin,
        window.location.pathname,
        this.queryString
      ].join('')
    }

  },
  methods: {

    getFilter (key) {
      return this.filters[key]
    },

    isThisOptionActive(filterKey, optionValue){
      var filter = this.getFilter(filterKey);

      if (!filter)
        return false;

      if (Array.isArray(filter))
        return filter.findIndex(x => x.value === optionValue) !== -1;

      return filter.value === optionValue;
    },

    setFilterAndClearPage (key, value, label = undefined) {
      return this.clearPaginationFilters()
        .then(_ => this.setFilter(key, value, label))
    },

    setFilter (key, value, label = undefined) {
      return this.onlySetFilter(key, value, label)
        .then(this.pushUrl)
        .then(this._searchIfThereAreFilters)
    },

    onlySetFilter (key, value, label = undefined) { 
      const filter = this.getFilter(key)
      if (Array.isArray(filter)) {
        if (value != null) {
          const valueIndex = filter.findIndex(x => x.value === value)
          if (valueIndex !== -1) {
            filter.splice(valueIndex, 1)
          } else {
            filter.push({
              key,
              value,
              label
            })
          }
        } else {
          this.$set(this.filters, key, [])
        }
      } else {
        const updatedFilter = (label !== undefined)
          ? { value, label }
          : { value, label: value }
  
        if (value != null) {
          this.$set(this.filters, key, updatedFilter)
        } else {
          this.$delete(this.filters, key)
        }
      }

      return Promise.resolve(this.filters[key])
    },

    clearFilter (key) {
      return this.onlyClearFilter(key)
        .then(this.clearPaginationFilters)
        .then(this._clearResponseIfNoFilters)
        .then(this.pushUrl)
        .then(this._searchIfThereAreFilters)
    },

    onlyClearFilter(key) {
      this.initialQueryParams = []
      return Promise.resolve(this.onlySetFilter(key, undefined))
    },

    clearPaginationFilters () {
      return Promise.all(
        this.paginationFilterNames
          .map(this.onlyClearFilter)
      )
    },

    clearAllFilters () {
      return Promise.all(
        Object.keys(this.filters)
          .map(this.onlyClearFilter)
      )
        .then(this.pushUrl)
    },

    clearAllFiltersBeforeNewSearch () {
      return Promise.all(
        Object.keys(this.filters)
          .map(this.onlyClearFilter)
      )
    },

    clearResponse () {
      this.$set(this, 'response', empty.response)
      return Promise.resolve(this.response)
    },

    replaceUrl () {
      window.history.replaceState(this.stateToCache, null, this.fullUrl)
      return Promise.resolve(this.stateToCache)
    },

    pushUrl () {
      window.history.pushState(this.stateToCache, null, this.fullUrl)
      return Promise.resolve(this.stateToCache)
    },

    // Performing searches
    search ({ onSuccess, onError } = {}) {
      if (this.apiUrl) {
        this.isSearching = true
        return axios.post(this.apiUrl)
          .then(onSuccess || this._onSearchSuccess)
          .catch(onError || this._onSearchError)
          .then(this.replaceUrl)
          .then(state => {
            this.isSearching = false
            return state
          })
      } else {
        const error = `No API endpoint provided, can't perform search.`
        return Promise.reject(error)
      }
    },

    // Scrolling to results
    scrollTo ({ id = 'results', offset = 0 } = {}) {
      const error = `Could not scroll, no element has id ${id}`
      return new Promise((resolve, reject) =>
        (document.getElementById(id))
          ? gsap.to(window, {
            duration: 1.2,
            scrollTo: {
              y: document.getElementById(id),
              offsetY: offset
            },
            onComplete: resolve
          })
          : reject(error)
      )
    },

    // Internal functions
    _doNothing () {},
    _searchIfThereAreFilters () {
      if (this.hasAnyFilters) {
        return this.search()
      }
    },
    _clearResponseIfNoFilters () {
      if (this.hasAnyFilters === false) {
        return this.clearResponse()
      }
    },
    _normalizeArray: (value) =>
      (value instanceof Array)
        ? value.join(',')
        : value,

    _setStateFromCache (pathname) {
      return ({ state }) => {
        if (window.location.pathname === pathname) {
          this.filters = state.filters
          this.response = state.response
        }
      }
    },

    _setupHistoryListener (pathname) {
      window.addEventListener('popstate', this._setStateFromCache(pathname))
    },

    _removeHistoryListener (pathname) {
      window.removeEventListener('popstate', this._setStateFromCache(pathname))
    },

    _onSearchSuccess ({ data }) {
      const response = data
      this.$set(this, 'response', response)
      return this.response
    },

    _onSearchError (reason) {
      console.error(reason)
      this.response = empty.response
      return reason
    }

  }
})

export default LandingPageMixin
