import Vue from 'vue';

Vue.component('Slider', {
  props: [ 'slides' ], //not necessary, can replace with just 'total' prop
  data: () => ({
    activeSlide: 0,
    clicked: false,
    //force vue to update computed properties on resize
    innerWidth: window.innerWidth,
  }),
  created () {
    var that = this;
    //force vue to update computed properties on resize
    window.addEventListener('resize', function() {
      that.updateInnerWidth()
    });
  },

  computed: {
    total() {
      return this.slides.length
    },
    slideWidth() {
      const els = document.querySelectorAll('.media-slider__card')
      const el = els[0]

      return (el.offsetWidth + 25)
    },
    slideSize() {
      if (this.innerWidth < 640) {
        return this.slideWidth
      } else if (this.innerWidth >= 640 && this.innerWidth < 1024) {
        return this.slideWidth * 2
      } else {
        return this.slideWidth * 3
      }
    },
    slideTotal() {
      if (this.innerWidth < 640) {
        return this.slides.length
      } else if (this.innerWidth >= 640 && this.innerWidth < 1024) {
        return Math.ceil(this.slides.length / 2)
      } else {
        return Math.ceil(this.slides.length / 3)
      }
    },
    slideRemainder() {
      if (this.innerWidth >= 640 && this.innerWidth < 1024) {
        return this.slideTotal - ( this.slides.length / 2 )
      } else if (this.innerWidth >= 1024) {
        return this.slideTotal - ( this.slides.length / 3 )
      } else {
        return 0
      }
    },
    slidePosition() {
      if (this.activeSlide < this.slideTotal -1) {
        return 'translateX(-' + this.slideSize * this.activeSlide + 'px)'
      } else {
        if ( this.slideRemainder > 0 ) {
          return 'translateX(-' + (( this.slideSize * this.activeSlide ) - ( this.slideSize * this.slideRemainder )) + 'px)'
        } else {
          return 'translateX(-' + this.slideSize * this.activeSlide + 'px)'
        }
      }
    },
    showControls() {
      return this.slideTotal > 1
    }
  },
  methods: {
    updateInnerWidth () {
      this.innerWidth = window.innerWidth
      // console.log(this.innerWidth)
    },
    prevSlide () {
      if (this.activeSlide > 0)
        this.activeSlide -= 1
      else
        this.activeSlide = this.slideTotal - 1

      this.stallClick();  
    },
    nextSlide () {
      if (this.activeSlide < this.slideTotal - 1)
        this.activeSlide += 1
      else
        this.activeSlide = 0

      this.stallClick();
    },
    stallClick () {
      this.clicked = true

      setTimeout(function(){
        this.clicked = false;
      }.bind(this),500);
    }
  },
  mounted() {
    
  }
})